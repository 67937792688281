import React from "react";
import { Link } from "react-router-dom";

/**
 * ContactMessage Component
 *
 * This component is responsible for displaying a success message
 * to the user once he/she submits the contact us form.
 *
 * @component
 * @example
 * return (
 *   <ContactMessage />
 * )
 */

const ContactMessage = () => {
  return (
    <div className="login-wrapper reset-message">
      <div className="container">
        <Link to="/">
          <div className="login-header">
            <img
              src={require("../assets/images/balanced-logo.png")}
              alt="Logo"
            />
          </div>
        </Link>
        <div className="login-container">
          <div className="login-card">
            <div className="login-body">
              <div className="reset-link-sent-card">
                <h3 className="text-center">
                  Thank you for reaching out!
                </h3>
                <p>
                  Your message has been successfully sent, and our team will
                  review it shortly. We will get back to youas soon as
                  possible.If you have any additional questions or need
                  immediate assistance, please feel free to contact us at{" "}
                  <a href="mailto:support@balanced.com.au">
                    support@balanced.com.au
                  </a>
                  .
                </p>
                <Link to="/" className="btn btn-primary">
                  Back to home page
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactMessage;

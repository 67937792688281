import React,{useState,useEffect} from "react";
import GetStartedCard from "../components/GetStartedCard";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import api from "../Api/AuthApi";
/**
 * Get Started Page
 * This page shows a list of all the treatments available
 * Each treatment card when clicked navigates to the questionnaire page
 */

const GetStarted = () => {
  const [treatmentCards, setTreatmentCards] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTreatmentCards = async () => {
      // Check for cached data in localStorage
      const cachedData = localStorage.getItem("treatmentCards");

      if (cachedData) {
        // Use cached data if available
        setTreatmentCards(JSON.parse(cachedData));
        setLoading(false);
      } else {
        // Fetch data from API if no cache found
        try {
          const response = await api.get("/api/treatments");
          const treatments = response.data.data;

          // Cache data in localStorage
          localStorage.setItem("treatmentCards", JSON.stringify(treatments));
          setTreatmentCards(treatments);
        } catch (error) {
          console.error("Error fetching treatments:", error);
          navigate("/page/error");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchTreatmentCards();
  }, [navigate]);

  if (loading) {
    return null;
  }
  return (
    <div className="getStarted_page">
      <Helmet>
        <title>Balanced | Get Started Page</title>
      </Helmet>
      <Link to="/">
        <div className="getStarted_header">
          <div className="getStarted_logo">
            <img
              src={require("../assets/images/balanced-logo.png")}
              alt="Logo"
            />
          </div>
        </div>
      </Link>
      <div className="container">
        <div className="getstarted_content">
          <h2 className="getStarted_title">Transform Your Health</h2>
          <p className="getStarted_description">
            Discover personalized health insights with our tailored tests.
            Choose the right one to enhance your lifestyle and well-being. Start
            your journey to a healthier you!
          </p>
        </div>
        <div className="d-flex justify-content-center">
          <div className="row justify-content-center">
            {treatmentCards
              .map((card, index) => {
                return (
                  <div
                    key={index}
                    className="col-lg-4 col-md-6 d-flex justify-content-center align-items-stretch mb-1"
                  >
                    <GetStartedCard
                      id={card.id}
                      title={card.name}
                      description={card.description}
                    />
                  </div>
                );
              })
              .reduce((rows, card, index) => {
                if (index % 2 === 0) {
                  rows.push([]);
                }
                rows[rows.length - 1].push(card);
                return rows;
              }, [])
              .map((row, index) => (
                <div className="row justify-content-center" key={index}>
                  {row}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStarted;

import React from "react";

/**
 * Doctors Card Component
 *
 * This component is used to display information about a doctor.
 * It takes the doctor's image, name, and role as props and
 * displays them in a card layout.
 *
 * Props:
 * - image: URL of the doctor's image.
 * - doctor_name: Name of the doctor.
 * - doctor_role: Role or description of the doctor.
 */

function DoctorsCard(props) {
  return (
    <div>
      <div className="doctors_card">
        <div className="card_body">
          <div className="card_top">
            <div className="card_img">
              <img src={props.image} alt="doctor" />
            </div>
          </div>
          <div className="card-bottom">
            <div className="card-bottom-text">
              <h6 className="doctors-name text-align-center">
                {props.doctor_name}
              </h6>

              <div className="doctors-info">
                <p className="doctors-description">{props.doctor_role}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DoctorsCard;

const loadGoogleMapsApi = async () => {
  // Check if the Google Maps script is already present
  if (document.querySelector('script[src*="maps.googleapis.com/maps/api/js"]')) {
    return; // Script is already loaded, so skip adding it again
  }

  try {
    // Fetch the Google Maps API key from the configured endpoint
    const response = await fetch(`${process.env.REACT_APP_URL}/api/config/maps-key`);
    const { key } = await response.json();

    if (key) {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${encodeURIComponent(key)}&libraries=places&loading=async&callback=initMap`;
        script.async = true;
        script.defer = true;

        // Resolve the promise once the script loads successfully
        script.onload = () => resolve(true);
        // Reject the promise if an error occurs
        script.onerror = (error) => reject(error);

        document.head.appendChild(script);
      });
    } else {
      console.error('API key is missing');
    }
  } catch (error) {
    console.error('Failed to load Google Maps script:', error);
  }
};

// Immediately call the function to load the Google Maps API
(async () => {
  try {
    await loadGoogleMapsApi();
    console.log('Google Maps API loaded successfully');
  } catch (error) {
    console.error('Failed to load Google Maps API:', error);
  }
})();

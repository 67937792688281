import React, { useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import api from "../Api/AuthApi";

/**
 * ResetPassword Component
 *
 * This component handles the "Reset Password" functionality where a user can reset
 * his password by providing a new one with it's confirmation. It includes frontend validation,
 * backend API interaction, and user feedback for errors.
 *
 * @component
 * @example
 * return (
 *   <ForgotPassword />
 * )
 */

const ResetPassword = () => {
  const { token } = useParams();
  // State variables to manage email input, errors, and navigation
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [backendError, setBackendError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Extract token and email from URL
  const email = new URLSearchParams(location.search).get("email");

  /**
   * validatePassword
   *
   * Function to validate the entered password format and check if it is not empty.
   * Provides user feedback for invalid or missing password.
   *
   * @returns {boolean} True if the password is valid, otherwise false.
   */
  console.log(token, email);
  const validatePasswords = () => {
    if (!newPassword || !confirmPassword) {
      setPasswordError("Password fields are required.");
      return false;
    } else if (newPassword !== confirmPassword) {
      setPasswordError("Passwords do not match.");
      return false;
    } else if (newPassword < 8) {
      setPasswordError("Password must be at least 8 characters long");
      return false;
    } else {
      setPasswordError("");
      return true;
    }
  };

  /**
   * handleSubmit
   *
   * Handles form submission to initiate the password reset process. It performs email
   * validation, interacts with the backend, and handles success/error responses.
   *
   * @param {object} e Event object
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    const isPasswordValid = validatePasswords();
    if (isPasswordValid) {
      setLoading(true);

      try {
        await api.get("/sanctum/csrf-cookie");
        const response = await api.post("/reset-password", {
          token,
          email,
          password: newPassword,
          password_confirmation: confirmPassword,
        });
        console.log(response);

        navigate("/users/reset-password-done");
      } catch (error) {
        if (error.response && error.response.status === 422) {
          // Handle 422 error (unprocessable content) - credentials error
          const errorMessage = "Invalid request. Try again.";
          setBackendError(errorMessage);
        } else {
          setBackendError("Failed to reset password. Please try again");
          navigate("/page/error");
        }
      }finally {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    }
  };

  return (
    <div className="login-wrapper password-wrapper">
      <Helmet>
        <title>Balanced | Reset Password</title>
      </Helmet>
      <div className="container">
        <Link to="/">
          <div className="login-header">
            <img
              src={require("../assets/images/balanced-logo-white.png")}
              alt="Logo"
            />
          </div>
        </Link>
        <div className="login-container">
          <div className="login-card">
            <div className="login-body">
              <p className="login-body-title password-body-title">
                Create a new password
              </p>
              <p className="password-body-description">
                Please enter a new password.
              </p>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="password"
                    className={`form-control ${
                      passwordError ? "is-invalid InputError" : ""
                    }`}
                    id="newPassword"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  {passwordError && (
                    <div className="invalid-feedback">{passwordError}</div>
                  )}
                  {backendError && (
                    <div className="alert alert-danger mt-3">
                      {backendError}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className={`form-control ${
                      passwordError ? "is-invalid InputError" : ""
                    }`}
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  {passwordError && (
                    <div className="invalid-feedback">{passwordError}</div>
                  )}
                  {backendError && (
                    <div className="alert alert-danger mt-3">
                      {backendError}
                    </div>
                  )}
                </div>
                <button
                  type="submit"
                  disabled={loading}
                  className="btn btn-primary btn-block login_pagebtn"
                >
                  {loading ? "Processing Request..." : "Reset Password"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

import React from "react";
import FAQCard from "./FaqCard";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

/**
 * Faq Section Component
 *
 * This component renders the FAQ section of the website, displaying common questions and answers about the platform.
 * It includes a title, a short description, and a list of FAQ items (questions and answers) rendered using the `FAQCard` component.
 *
 * Props:
 * - FaqData: An array of question-answer pairs representing the frequently asked questions.
 */

function Faq() {
  const FaqData = [
    {
      question: "How does it work?",
      answer:
        "Balanced is a telehealth platform that offers accessible, convenient, and proactive care for various areas of health and wellness including anti-aging, vitality, cognitive enhancement, weight loss, muscle support, repair & recovery, libido enhancement, sleep, hair loss, immunity, and skincare. We offer a wide range of personalised solutions including prescription and non-prescription products designed to meet your unique needs no matter where you are on your wellness journey. Getting started is simple: Complete an online intake form to set up your account and book your telehealth assessment. Share your symptoms and medical history with a clinician to see if Balanced is the right fit for you. Your provider will contact you for a free consultation to assess your needs and recommend the best treatment plan for you. After your consultation, confirm your plan and we'll take care of the rest—finalising the details with our partner pharmacy and shipping your customised treatments directly to your door.",
    },
    {
      question: "Where is Balanced available?",
      answer:
        "Balanced is currently only available in all states and territories of Australia.",
    },
    {
      question: "Is there a fee for a consultation with a prescriber?",
      answer:
        "All consultations are complimentary. We believe that access to proactive healthcare should be free and accessible to everyone. With Balanced, you'll enjoy unlimited access to continuous support, check-ins with your clinician, and more.",
    },
    {
      question: "How does shipping work?",
      answer:
        "Once your order ships, you'll receive an email with a tracking link. Log into your account to track your package under 'Orders'.",
    },
    {
      question: "Do I need a prescription?",
      answer:
        "Some products require a prescription from a licensed practitioner. You'll need a consultation through our platform to get one.",
    },
  ];

  // Smooth scroll to top of the page when clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="faq_section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-6 col-md-5 mb-5">
                <div className="left_section">
                  <h4 className="left_title" data-aos="fade-up">
                    Get Answers To All <span>Your Question</span>
                  </h4>
                  <p className="left_description" data-aos="fade-up">
                    Here you can find answers to all your question,feel free to
                    contact us if you didn’t find the answers to your questions
                    <Link
                      to="/contact-us"
                      className="link"
                      onClick={scrollToTop}
                    >
                      {" "}
                      here.
                    </Link>
                  </p>
                  <Link
                    to="/contact-us"
                    data-aos="fade-up"
                    onClick={scrollToTop}
                  >
                    <button className="ctaBtn contactUs_Btn">Contact Us</button>
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 col-md-7">
                {FaqData.map((Faq, index) => (
                  <div
                    className="faq_card_wrapper"
                    key={index}
                    data-aos="fade-up"
                  >
                    <FAQCard question={Faq.question} answer={Faq.answer} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
